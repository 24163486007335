*{
    padding: 0;
    margin: 0;
}
.order {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #b2afaf;
    width: 50%;
    max-width: 500px;
    padding: 20px;
    border-radius: 8px;
}
.orderPage {
    overflow-y: auto;
    max-height: 80vh; 
}
.dadosCompras{
    width: 100%;
    display: block;
    margin-top: 3%;
    margin-left: 3%;
}
.dadosCompras p {
    padding: 7px;
    font-size: small;
    font-family: sans-serif;
}
.stripe {
    width: 95%;
    display: flex;
    border-top: 1px solid rgb(144, 142, 142); 
    margin-top: 10px; 
    margin-bottom: 10px;     
}
.buttonConfirmar{
    background-color: #ccc;
    width: 57%;
    height: 25px;
    background-color: #928d8d;
    font-family: Proxima Nova,-apple-system,Roboto,Arial,sans-serif;
    font-size: 16px;
    font-weight: 600;
    border-radius: 4px;
    padding: 0 24px;
    text-align: center;
    border: none;    
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 50%;
    transform: translate(-50%);
    color: beige;
}
