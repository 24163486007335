*{
    padding: 0;
    margin: 0;
}
.edit {
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
}
.span:focus {
    color:black;
}