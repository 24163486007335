*{
    margin: 0;
    padding: 0;
}

.modalCep {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    max-width: 600px; /* Limite a largura máxima */
    height: auto; /* Altura automática baseada no conteúdo */
    max-height: 80vh; /* Limite a altura máxima para 80% da viewport */
    background-color: white; /* Certifique-se de que há uma cor de fundo */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    z-index: 1000;  
}
.closeModal {
    display: flex;
    justify-content: end;
    width: 100%;
    cursor: pointer;
    /* background-color: red; */
}
.modal-content {
 display: flex;
  width: 100%;  
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: red; */
}
._cepNumber {
    background-color: rgb(250, 250, 168);
}
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 999;
}
