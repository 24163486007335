 @media screen and (max-width: 600px) {

    .headContainner {      
       height: 130px;     
    }
    .divNav {
        background-color: beige;
    }
    .separate {       
        width: 100%;
        height: 100%;
        margin: 0;            
    }
    .section-container {              
        display: grid;
        grid-template-columns: 1fr; 
        width: 100%;
        margin-left: 5px;
        margin-right: 11px;             
    }
    .grid-left { 
        display: flex;
        justify-content: center;
        align-items: center;   
        width: 100%;   
        height: 100%;      
        border-radius: 4px;         
    }      
    .container_ContactDataForm {
        display: flex;       
        width: 100%;
        height: 100%;
    }
    .info-login {
        display: block;
        width: 100%;
        height: 100%;         
    }  
    .text-tittle {          
        width: 100%;
        height: 20px;           
        font-size: 7px;        
        background-color: rgb(247, 195, 99);
        font-weight: 800;
        text-transform: uppercase;
        text-align: center;
        text-indent: 0px;
    }
.iDataClints {   
    margin-top: 2px;
    height: 35px;
}
.iDataClints label:nth-of-type(1) {
    font-size: 10px;
}
.iDataClints input:nth-last-of-type(1) {
    height: 20px;
    border-radius: 0px;
    font-size: 10px;
}
.divButtonContact {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: white;
    height: 25px;
     margin: 0;
     margin-top: 5px;
}
.bottonToSend {
     width: 70%;
    height: 20px;
    font-size: 10px;
    margin: 0;   
}    
._areaContact {
    display: flex;
    justify-content: center;
    align-items:center ;   
    width: 100%;
    height: 100%;        
    margin: 0;
}
.messageArea {
    display: flex;   
    width: 100%;
    height: 100%;    
    margin-left: 0;
    font-size: 10px;
    padding-top: 10px;
}
.grid-right {  
    display: none;        
}
.info-photo {
    display: none;
    justify-content: center;
    align-items: center; 
    height: 100%;
     width: 100%;
     margin: 0;
    }
    .img-telefonista {        
    width: 100%;
    height: 100%;
    border-radius: 4px;
        display: none;
    }   
    ._footerContainer p {
        margin-top: 10px;
    }   
    ._footer_home_description {
        color: #fff;
    }
 }