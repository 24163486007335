*{
    padding: 0;
    margin: 0;
}
.all {
background-color: #ccc;
width: 100%;
height: 100%;
}
.all h1 {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    background-color: #222;
    color: #ccc;
    height: 80px;
    align-items: center;    
}
.all p {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-left: -20%;
    text-indent: 30px;    
}
.all button {
    display: flex;
    justify-content: center;
    padding: 15px;
    width: 300px;
    margin-top: 20px;
    margin-left: 50%;
    transform: translate(-50%);
    background-color: #2229;
    color: #ccc ;
    font-size: 15px;
}
