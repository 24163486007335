
@media (max-width: 480px) and (min-width: 320px) {

    body {
        overflow-y: auto; /* Garante que a página pode rolar verticalmente */
    }
    .modal-address {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5); 
        justify-content: center;
        align-items: center;
        z-index: 999;      
    }
.container {
    display: grid;
    grid-template-columns: 15% 35% 50%;
    width: 100%;
    min-height: 500px;
    padding: 2px;
    justify-content: space-between;
    margin-top: 20px;
    border-radius: 5px;
    background-color: #fff;  
    margin: 0;
    /* background-color: olivedrab; */
    
  }
  
.allSelect {   
    padding: 5px;
    width: 100%;
    height: 100%;
    /* background-color: #eaeaea;    */
    /* background-color: teal; */
    
   
}
.mCompras {
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    font-size: small;
    letter-spacing: .05rem;
    /* margin-left: -20px; */
    cursor: pointer;
    grid-gap: 10px;color: #5d5d5d;
    /* background-color: red; */
}

.sectionLeft {   
    width: 100%;
    height: 250px;
    display: block;
    display: grid;
    background-color:   rgb(232, 229, 229);
    justify-content: center;
    overflow: scroll;
    border-radius: 4px 0px 0px 4px;
  }
  .secton_SalesMain {
      display: flex;        
      width: 100%;
      height: 250px;
      /* background-color: maroon */
     
  }
  .sectionRight {
    display: flex;
    justify-content: center;
      width: 100%;
      /* background-color: #fff;   */
      border-radius: 6px;
      /* background-color: skyblue; */
      /* overflow: none; */
     
  }  


._starSales {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* background-color: red; */
}
._imgStar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75%;   
}

  
.opinion {
    display: grid;
    grid-template-columns: 30% 25% 45%;
    background-color: none;
    margin-top: 15px;
    width: 100%;
    height: 20px;  
    /* background-color: teal;   */
}

._opnionText {
    max-width: 80%;
    display: block;
    margin-left: 10px;
}
._opnionText:nth-of-type(1) p {
    width: 100%;
    font-size: 8px;    
    /* background-color: green; */
}

._opnionText:nth-of-type(2) p {
    width: 100%;
    font-size: 8px;    
    /* background-color: green; */
}

.valueoff {
    display: flex;
    width: 100%;
    align-items: center;
    /* background-color: red; */
}

.valueTot {
    font-size: 14px;
    font-weight: 600;
}
.valueTot span {
    font-size: 8px;
}
.valueDesc span {
  position: absolute;
  font-size: 8px;
  text-decoration: line-through;
}






  .star:nth-of-type(1) p {
    /* background-color: red; */
    /* font-size: 12px; */

  }
 
  .scrool {
    display: flex;
      width: 100%;
      height: 80px;
      display: flex;    
      justify-content: center;   
      align-items: center;  
      border-radius: 5px;   
      /* background-color: greenyellow; */
     
  }
  .imgSales {
      width: 100%;
      height: 60px;  
      border-radius: 5px;
      border: 2px solid hsl(0, 0%, 80%,.5);
      margin-top: 5px;   
      grid-gap: 30px;
  }
  .photo img {
     width: 100%;
     height: 250px;
     background-color: blue;
  }
  .marca {
     color: blue; 
     margin-top: 10px;    
     font-size: 10px;
     font-family: Arial, Helvetica, sans-serif;
  }
.pDescription { 
   font-weight: 500;
   margin-top: 10px;
   font-size: 12px;
}
.bestseller {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    width: 100%;
    height: 15px;
    background-color: orange;
    color: white; 
   font-family: sans-serif;
   text-transform: uppercase;
   font-size: 5px;   
   font-family: Arial, Helvetica, sans-serif;
   /* letter-spacing: 1px;*/
   
}
 .valueDesc {
    /* background-color: green; */
    font-size: 14px;
    margin-top: 10px;
}

.descriptionOff {
    color: green;
    font-weight: 600;
    margin-left: 25px;
    font-size: 7px;
  }
  .descriptionParcelado {
    display: flex;       
  }
  .descriptionParcelado p {
    font-size: 8px;
  }

  .descriptionParcelado span {
    position: absolute;
    font-size: 8px;
    font-family: 'Times New Roman', Times, serif;
    color: black;
    /* background-color: violet; */
  }
  .description {
      border: 1px solid #ccc;
      margin-top: 1px;
      border-radius: 5px;
      padding: 7px;
      width: 90%;
  }
  .pagtos {
    margin-top: 7px;
    color:blue;
    font-size: 10px;
     font-family: Arial, Helvetica, sans-serif;
  }
  .entrega {
    margin-top: 15px;
    display: flex;
    color: green;
    font-weight: 600;   
    /* background-color: red; */
  }
  .entrega p:nth-last-of-type(1) {
    font-size: 8px;    
}
.entrega p:nth-last-of-type(2) {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: aqua; */
    font-size: 8px;
}
.entrega p:nth-last-of-type(3) {
    color:blue;
    font-size: small;
    margin-top: 5px;
    background-color: green;
}
  .fpagto {
      font-size: 10px;
     font-family: Arial, Helvetica, sans-serif;    
  }
  .retorno {
    margin-top: 15px;
    font-size: 10px;
    display: flex;
    color: green;
    font-weight: 600;
    /* background-color: red; */
  }
  .saibamais {
    color:blue;
    font-size: 10px;
    margin-top: 7px;
    /* background-color: red; */
  }
  .prazo {
    color: #2229;
    font-size: 10px;
    margin-top: 5px;
    /* background-color: red; */
  }
  .envio {
    margin-top: 15px;
    color: #808080;
    font-size: 10px;
    /* background-color: green; */
  }
  .formasEnvio {
   font-size: 10px;
    margin-top: 5px;
    color: #2229;
    /* background-color: red; */
  }
  .calcularFrete {
    color:blue;
    font-size: 10px;
    margin-top: 7px;
    /* background-color: green; */
  }
  .cores {
    font-size: large; 
    color: #2229;
  }
  .descriptionCor {
    display: flex;  
    margin-top: 15px;
    grid-gap: 5px;
    /* background-color: red; */
   }
  .descriptionCor p {
    color: #2229;
    font-size: 10px;
  }
  .descriptionSize {
         display: flex;
      align-items: center;
      margin-top: 15px;
      grid-gap: 10px;   
      /* background-color: red; */
  }
  .descriptionSize p {
    color: #2229;
    margin: 0;
    font-size: 10px;
  }
  .gradecor{
    display: flex;    
    margin-top: 5px;
    gap: 15px;
    cursor: pointer;  
    text-align: center;  
    /* background-color: red; */
  }
  .gradecor div:nth-of-type(1) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    height: 50px;
    /* background-color: teal; */
  }
  .gradecor div:nth-of-type(2) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    height: 50px;
    /* background-color: teal; */
   
  }
  .gradecor div:nth-of-type(3) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    height: 50px;
    /* background-color: teal; */
  }
  .gradecor img:nth-of-type(1) {
    width: 100%;
    height: 90%;
  }
  .gradecor img:nth-of-type(2) {
    width: 100%;
    height: 100%;
  }
  .gradecor img:nth-of-type(3) {
    width: 100%;
    height: 100%;
  }

  .gradecor img{
      margin-top: 3px;
      width: 63%;  
      height: 100%;  
  }
  .gradeTamanho{
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    height: 2%;
    margin-top: 5px;
    gap: 5px;
    cursor: pointer;
    /* background-color: red; */
  }
  .size {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    width: 10%;
    height: 100%;
    padding: 0;
    border-radius: 50%;
    background-color: #fff;
    font-size: 10px;
  }
  .size:hover {
    color: #2229;
    font-size: 10px;
    border: 1px solid #222;
    background-color: #ccc;


      /*  font-weight: 900; */
  }
  .colors:hover {
      border: 1px solid blue;
  }
  .divOne{
    width: 15%;
    height: 80px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  .divTwo{
    width: 15%;
    height: 80px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  .divThree{
    width: 15%;
    height: 80px;
    border-radius: 5px;
    border: 1px solid #ccc;
  } 
  .estoque {
    font-size: large;
    margin-top: 25px;
    color: #2229;
  }
  .guia{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;   
    margin-top: 15px;
  }
  .guia span {
    font-size: 10px;    
  }
 
  .guia p { 
    color: blue;
    font-size: 10px;  
    text-decoration: underline;  
  }
  .disponivel {
    font-size: 10px;
    margin-top: 15px;
    color: #2229;
    /* background-color: red; */
  }
  ._labelCep{
    margin-top: 15px;
    font-size: 10px;
    font-weight: 600;
    
  }
  .InputCep {
    display: flex;
    justify-content: center;
    align-items: center;   
    width: 100%;
    height: 20px;
    border: none;
    /* background-color:green; */
  }
  .InputCep a{  
    width: 100%;
    font-size: 9px;
    color: blue;
    text-decoration: none;
  }
  .InputCep input {
      width: 100%;
      height: 15px;
      margin-right: 10px;
      border-radius: 2px;     
      outline: none;
      border: 1px solid black;     
      font-size: 8px;   
  }
  .freteGratis{
    margin-top: 7px;
    font-size: 10px;
    display: flex;
    color: #2229;
    font-weight: 600;  
    /* background-color: red; */
  }
  .buttonComprar{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 7px;
    background-color: blue;
    text-transform: uppercase;
    width: 88%;
    height: 20px;
    color: #fff;
    font-family: Proxima Nova,-apple-system,Roboto,Arial,sans-serif;
    font-size: 8px;
    letter-spacing: 1px;
    font-weight: 600;
    border-radius: 4px;
    padding: 0 24px;   
    border: none;
    margin-left: 50%;
    transform: translate(-50%);
    cursor: pointer;
  }
  .buttonAdicionar{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    background-color: green;
    text-transform: uppercase;
    width: 88%;
    height: 20px;
    color: #fff;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 8px;
    letter-spacing: 1px;
    transform: scaleY(1.5);   
    border-radius: 4px;
    padding: 0 24px;   
    border: none;
    margin-left: 50%;
    transform: translate(-50%);
  }
  .vendidoPor {
      font-size: 10px;
      margin-top: 10px;
      color: #2229;     
      /* background-color: red;  */
  }
  .vendidoPor  span {
      font-size: 8px;
      font-weight: 600;
      color:blue;
  }
  .garantia {
      font-size: 10px;
      margin-top: 10px;
      color: #2229;
      /* background-color: red; */
  }
  .bold {
      color: blue;
      border: 1px solid blue;
  }
 
}