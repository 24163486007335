*{
    padding: 0;
    margin: 0;
}
.ContainerModalGuiaTamanho {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;   
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.441);
}
.containerGuiaTamanho {    
    width: 60%;   
    background-color: #ccc;
    border: 1px solid #222;
}
.containerGuiaTamanho section {
    display: flex;
    width: 100%;
    height: 40px;
    background-color: teal;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 400;
    color: white;    
}
.number_bold {
 font-weight: bold;
}
._naveMeasure {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 60%;
    height: 100px;
    margin: 0;   
    gap: 10px;
}
._naveMeasure section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-color: teal;
}
.custom-icon {
    font-size: 35px !important;
    fill: white !important;
}


.guiaDescriptionMeasures {
display: grid;
width: 60%;
height: 40px;
grid-template-columns: repeat(9, 1fr);
background-color: #ccc;   
 }
 .guiaDescriptionMeasures:nth-child(odd) {
    background-color: beige;
 }
 .guiaDescriptionMeasures section {
 border-right: 1px solid #222;
 border-left: 1px solid #222;
 border-bottom: 1px solid #222;
}
.guiaDescriptionMeasures section:nth-of-type(1) {
    display: flex;
    justify-content: center;   
    align-items: center;
    width: 100%;   
    border-right: none;
}
.guiaDescriptionMeasures section:nth-of-type(2) {
    display: flex;
    justify-content: center;   
    align-items: center;
    width: 100%;
    border-right: none;  
}
.guiaDescriptionMeasures section:nth-of-type(3) {
    display: flex;
    justify-content: center;   
    align-items: center;
    width: 100%;
    border-right: none;   
}
.guiaDescriptionMeasures section:nth-of-type(4) {
    display: flex;
    justify-content: center;   
    align-items: center;
    width: 100%;
    border-right: none;    
}
.guiaDescriptionMeasures section:nth-of-type(5) {
    display: flex;
    justify-content: center;   
    align-items: center;
    width: 100%;
    border-right: none;  
}
.guiaDescriptionMeasures section:nth-of-type(6) {
    display: flex;
    justify-content: center;   
    align-items: center;
    width: 100%;
    border-right: none;   
}
.guiaDescriptionMeasures section:nth-of-type(7) {
    display: flex;
    justify-content: center;   
    align-items: center;
    width: 100%;
    border-right: none;   
}
.guiaDescriptionMeasures section:nth-of-type(8) {
    display: flex;
    justify-content: center;   
    align-items: center;
    width: 100%;
    border-right: none;   
}
.guiaDescriptionMeasures section:nth-of-type(9) {
    display: flex;
    justify-content: center;   
    align-items: center;
    width: 100%;   
}