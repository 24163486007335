*{
    padding: 0;
    margin: 0;
}
.containerLogin {
    width: 100vw;
    height: 100vh;
    background-color: #ccc;
    margin: 0;
}
.h1 {
     width: 100%;   
     padding: 15px;
     text-align: center;
     background-color: #a6a5a5;
     border-radius: 4px 4px 0 0;
     font-family: sans-serif;
     font-size: x-large;
}
.icon {
    margin-left: 50%;
}
.box {
    position: fixed;
    background-color: #e9e9e9;   
    width: 40%;
    height: 60%;
    border-radius: 4px;
    margin-left: 50%;
    transform: translate(-50%, 30%);
}
.dados {
    display: box;
    margin-left: 50%;
    transform: translate( -50%);
    padding: 5px;
    outline: none;
}
.user:nth-child(1) {
    font-size: 25px;
    margin-top: 20px;
    margin-left: 50%;
    transform: translate(-60%);
}
.user:nth-child(2) {
    width: 60%;
    height: 40px;
    background-color: rgb(250, 246, 176, .5);
    border: 1px solid #989696;
    font-size: 14px;
    text-transform: uppercase;
    margin-left: 50%;
    transform: translate(-50%);
    outline: none;
    text-indent: 10px;
}
.user:nth-child(3) {
    text-align: start;
    color: red;
    font-size: 12px;
    margin-left: 50%;
    transform: translate(-50%);
    width: 60%;
}
.user:nth-child(4) {
    font-size: 25px;
    margin-top: 25px;
    margin-left: 50%;
    transform: translate(-60%);
}
.campoInput {
    display: flex;
    width: 60%;   
    height: 40px;
    justify-content: end;
    align-items: center;
    margin-left: 50%;
    transform: translate(-50%);
}
.inputSenha {
    position: absolute;
    width: 100%;
    height: 40px;
    background-color: rgb(250, 246, 176, .5);
  border: 1px solid #989696;
    font-size: 15px;
    cursor: pointer;
    outline: none;
    text-indent: 20px;
    z-index: 1;
   
}
.onOffEye {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15%;
    cursor: pointer;
    z-index: 10;
   }
.user:nth-child(6) {
    color: red;
    font-size: 12px;
    margin-left: 50%;
    transform: translate(-60%);
}
.user:nth-child(7) {
    margin-top: 35px;
    margin-bottom: 20px;
    min-height: 35px;
    font-size: 15px;
    background-color: blue;
    color: white; 
    margin-left: 50%;
    transform: translate(-50%);
}