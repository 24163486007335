* {
    padding: 0;
    margin: 0;
}
.headContainner {
    display: flex;
    justify-content: center;
}
.grid-section-man {   
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 20px;
    justify-content: center;
    padding: 10px;
}
.collumn-item:nth-child(1) {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-row: 1/3;
    grid-column: 1/2;
    position: relative;
}
.img-one {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 3px;
}
.collumn-item:nth-child(2) {
    display: grid;
    grid-column: 2/3;
    grid-row: 1;
    background-color: rgb(255, 74, 3);
}
.img-two {
    width: 100%;
    height: 100%;
    border-radius: 3px;
}
.collumn-item:nth-child(3) {
    display: grid;
    grid-column: 2/3;
    grid-row: 2;
    background-color: rgb(246, 4, 4);
}
.img-third {
    width: 100%;
    height: 100%;
    border-radius: 3px;
}
.collumn-item {
    border-radius: 3px;
}
.text {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: center;
    cursor: pointer;  
}
.collumn-item-two p {
    color: blue;
    font-size: 12px;
}
.collumn-item-two h3 {
    color: blue;
    font-size: 12px;
    margin-left: -10px;       
}
.photobook {
    display: flex;
    width: 100%;
    height:70px;
    margin-top: 50px;
    justify-content: center;
    align-items: center;
} 
.photobook h1 {
    border-top: 2px solid #222 ;
    border-bottom: 2px solid #222;
}
.grid-collumn-second {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 3;
    margin-top: 50px;    
    width: 95%;   
    grid-gap: 20px;
}
.collumn-item-two {
    display: grid;
    width: 100%;
    height: 100%; 
    justify-content: center;
    align-items: center;
    background-color: #ccc;
    border-bottom: 5px solid #222;
}
.collumn-item-two img {
    width: 100%;
    height: 100%;
    border-radius: 4px 4px 0 0;   
}
.collumn-item-two:nth-child(1) {   
    grid-row: 1;   
}
.collumn-item-two:nth-child(2) {   
     grid-row: 1;    
}
.collumn-item-two:nth-child(3) {   
     grid-row: 1;   
}
.collumn-item-two:nth-child(4) {   
     grid-row: 1;   
}
.collumn-item-two:nth-child(5) {  
    grid-row: 2;    
}
.collumn-item-two:nth-child(6) {    
    grid-row: 2;   
}
.collumn-item-two:nth-child(7) {    
    grid-row: 2;    
}
.collumn-item-two:nth-child(8) {    
    grid-row: 2;    
}
.grid-collumn-second h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;    
    margin-bottom: 5px;
    margin-top: 5px; 
}
.hide {
    display: none;
}
.h {
    display: none;
}
