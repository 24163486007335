
@media (max-width: 480px) and (min-width: 320px) {


.ContainerModalGuiaTamanho {
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;   
    width: 100wh;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.441);
   
}
._naveMeasure {     
    display: flex; 
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 30px;
    margin-top: -100px;   
    gap: 10px;
   
}
._naveMeasure section {
    display: flex;
   flex-direction: column;
   align-items: center;
    width: 30px;
    height: 30px;
    background-color: teal;
    cursor: pointer;
}
.custom-icon {
    display: block;  
    font-size: 20px !important;
    fill: white !important;
}
._naveMeasure p { 
    margin: 0;
    color: white;
    font-size: 10px;
    margin-right: 15px;
}

.containerGuiaTamanho {    
    display: flex;
    width: 98%;   
    background-color: #ccc;
    border: 1px solid #222;
    margin-top: 35px;
    margin-left: 1%;
    margin-right: 1%;
}
.containerGuiaTamanho section {
    display: flex;
    width: 100%;
    height: 40px;
    background-color: teal;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 400;
    color: white;    
}

.number_bold {
    font-weight: bold;
    font-size: 10px;
}
.number_bold section {
    font-size: 9px;

}
._unique {
    margin-top: 3px;
}
.guiaDescriptionMeasures {
    display: grid;
    width: 98%;
    height: 30px;
    grid-template-columns: repeat(9, 1fr);
    background-color: #ccc;         
    }
 .guiaDescriptionMeasures:nth-child(odd) {
    background-color: beige; 
    font-family: Arial, Helvetica, sans-serif;
 }
 .guiaDescriptionMeasures section {
 border-right: 1px solid #222;
 border-left: 1px solid #222;
 border-bottom: 1px solid #222;
}
.guiaDescriptionMeasures section:nth-of-type(1) {
    display: flex;
    justify-content: center;   
    align-items: center;
    width: 100%;   
    border-right: none;
    font-size: 10px;
}
.guiaDescriptionMeasures section:nth-of-type(2) {
    display: flex;
    justify-content: center;   
    align-items: center;
    width: 100%;
    border-right: none;  
    font-size: 10px;
}
.guiaDescriptionMeasures section:nth-of-type(3) {
    display: flex;
    justify-content: center;   
    align-items: center;
    width: 100%;
    border-right: none;   
    font-size: 10px;
}
.guiaDescriptionMeasures section:nth-of-type(4) {
    display: flex;
    justify-content: center;   
    align-items: center;
    width: 100%;
    border-right: none; 
    font-size: 10px;   
}
.guiaDescriptionMeasures section:nth-of-type(5) {
    display: flex;
    justify-content: center;   
    align-items: center;
    width: 100%;
    border-right: none;  
    font-size: 10px;
}
.guiaDescriptionMeasures section:nth-of-type(6) {
    display: flex;
    justify-content: center;   
    align-items: center;
    width: 100%;
    border-right: none;  
    font-size: 10px; 
}
.guiaDescriptionMeasures section:nth-of-type(7) {
    display: flex;
    justify-content: center;   
    align-items: center;
    width: 100%;
    border-right: none;   
    font-size: 10px;
}
.guiaDescriptionMeasures section:nth-of-type(8) {
    display: flex;
    justify-content: center;   
    align-items: center;
    width: 100%;
    border-right: none;   
    font-size: 10px;
}
.guiaDescriptionMeasures section:nth-of-type(9) {
    display: flex;
    justify-content: center;   
    align-items: center;
    width: 100%;   
    font-size: 10px;
}
}