body,
h1,
ul,
li,
p,
dd,
dt,
dl,
h2,
* {
    padding: 0;
    margin: 0;
}
:root {
    --corBeige: beige;
    --corRodape: #222;
    --corTittle: orange;
    --corTextGray: #7f7e7e;
}
.headContainner {
    width: 100%;
    height: 100%;
}
.separate {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    margin-top: 70px;   
}
.section-container {
    display: grid;
    width: 100%; 
    grid-template-columns: 70% 30%;      
     margin: 0;            
    border-radius: 4px;
    grid-gap: 10px;  
    margin-left: 12px;
    margin-right: 42px;    
}
.grid-left {
    display: flex;    
    width: 100%; 
    height: 100%;
    justify-content: center;    
    align-items: center;      
    border-radius: 4px;
    grid-column: 1;   
}
.container_ContactDataForm {
    display: grid;
    width: 100%;   
    height: 100%;    
    grid-template-columns: 40% 60%;
    grid-gap: 10px;     
}
.info-login {
    display: block;
    width: 100%; 
    border-radius: 4px;
    margin: 0;  
    border: 1px solid var(--corRodape);
}
.text-tittle {
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.8;
    color: #222;
    font-weight: 400;
    height: 40px;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    text-indent: 20px;
    text-transform: capitalize;
    letter-spacing: 1px;
    font-weight: 600;   
    background-color: rgb(242, 196, 112);
    border-radius: 4px 4px 0px 0px;  
    text-transform: uppercase;  
}
.iDataClints {
    display: flex;
    flex-direction: column;
    width: calc(100% - 20px);
    max-width: 100%; 
    height: 50px;
    margin-top: 20px;   
    margin-left: 10px;  
    box-sizing: border-box;
}
.iDataClints label {
    display: flex;   
    font-size: 12px;     
}
.iDataClints:nth-of-type(1) input {
    width: 100%;
    height: 40px;
    background-color: white;
    border-radius: 4px;
    border: 1px solid black;
    outline: none;
    text-indent: 5px;
}
.iDataClints:nth-of-type(2) input {
    width: 100%;
    height: 40px;
    background-color: white;
    border-radius: 4px;
    border: 1px solid black;
    outline: none;
    text-indent: 5px;
}
.divButtonContact {
    display: flex;
    width: calc(100% - 20px);
    height: 70px;
    border-radius: 4px;    
    margin-top: 10px;
    align-items: center;
    justify-content: end;   
    margin-left: 10px;
    border-radius: 4px;   
}

.bottonToSend {
    background-color: rgb(60, 60, 198);
    width: 100%;
    height: 30px;
    color: white;
    font-size: 17px;
    text-transform: capitalize;
    letter-spacing: 1px; 
    margin-top: 50px;  
}
.bottonToSend:hover {
    background-color: #4639fa;
}   
._areaContact {
   display: flex;
   justify-content: center;   
   width: calc(100% -10px); 
   margin-top: 1px;
   border-radius: 4px;   
   margin-right: 10px;
}
.messageArea {
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 100%; /* Limita a largura da área de texto */      
    border-radius: 4px;
    background-color: beige;
    border: 1px solid gray;
    color: black;
    text-indent: 10px;  
    padding-top: 20px; 
    font-size: 16px;
    outline: none;   
}
.grid-right {
    display: flex;    
    justify-content: center;
    align-items: center;  
    width: 100%;
    height: 100%;  
    grid-column: 2;    
}
.info-photo {
    display: flex;
    justify-content: center;
    align-items: center;    
    background-color: rgb(252, 252, 184);
    width: 100%;   
    border-radius: 4px;
    border:  1px solid #222;
}
.img-telefonista {
    width: 100%;  
    margin-top: 0.8px;   
    margin-bottom: 0.8px;
    margin-left: 0.8px;
    margin-right: 0.8px; 
    max-width: 100%; /* Limita a largura da imagem */
    height: auto; /* Mantém a proporção da imagem */
}
.secaoEmail {
    display: block;
    width: 100%;
    margin: 0;
    margin-top: 5px;
}




