* {
  padding: 0;
  margin: 0;
}
.hidden{
    display: none;
} 
.address-hidden {
    opacity: 0; /* Torna o componente invisível */
    pointer-events: none; /* Impede que o componente receba eventos do mouse */
    transition: opacity 0.3s ease; /* Adiciona uma transição suave para a opacidade */
}
.modal-address {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); 
    justify-content: center;
    align-items: center;
    z-index: 999;      
}
.modal-address.active {  
    display: flex; 
}
.divLeft-Sales {
    margin-left: 20px;
}
.information:nth-child(1){
  margin-top: 5px;
  font-size: larger;
  font-weight: 600;
  color: #222;
  font-family: Arial, Helvetica, sans-serif;
}
.information:nth-child(2){
  margin-top: 5px;
  font-size: small;
}
.information:nth-child(3){
  margin-top: 30px;
  color: blue;
  text-decoration: underline;
}
/* nav */
.divNav-Sales {    
  display: grid;
  width: 100vw;
  height: 18vh;
  grid-template-columns: 42% 16% 42%;
  justify-content: center;
  align-items: centers;
  margin: 0;
  z-index: 1;
}









.logokz-sales {
  width: 30%;
}
.article-sales h1{
  font-size: 14px;
  color: #2229;
}
.divCenter-Sales {
  display: grid;
}
.divCenter-Sales section {
  display: flex;
  justify-content: center;
  align-items: center;
}
.divCenter-Sales article {
  display: flex;
  justify-content: center;
}
.container {
  width: 100%;
  /* grid-template-columns: 20% 40% 40%;   */
  padding: 2px;
  justify-content: space-between;
  margin-top: 20px;
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  overflow: auto;
}
.allSelect {   
    padding: 30px;
    width: 100%;
    height: 100%;
    background-color: #eaeaea;    
}
.mCompras {
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    font-size: large;
    letter-spacing: .05rem;
    margin-left: -20px;
    cursor: pointer;
    grid-gap: 10px;color: #5d5d5d;
}
.mCompras:hover {
    color: #808080;
}
.sectionLeft {   
  width: 10%;
  height: 600px;
  border-radius: 6px; 
  display: block;
  display: grid;
}
.sectonMain {
    display: flex;
    justify-content: center;
    width: 40%;
}
.sectionRight {
    width: 50%;
    background-color: #fff;  
    border-radius: 6px;
}
.scrool {
    width: 65%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;  
    border-radius: 5px;   
    justify-content: center;   
}
.imgSales {
    width: 70%;
    height: 65px;  
    border-radius: 5px;
    border: 2px solid hsl(0, 0%, 80%,.5);
    margin-top: 30px;   
    grid-gap: 30px;
}
.photo img {
   width: 90%;
   height: 650px;
}
.marca {
   color: blue; 
   font-size: small;
   margin-top: 10px;
}
.pDescription {
   font-size: 25px;
   font-weight: 500;
   margin-top: 10px;
}
.bestseller {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    width: 100%;
    background-color: orange;
    color: white; 
   font-family: sans-serif;
   text-transform: uppercase;
   font-size: 12px;   
}

.opinion {
    display: grid;
    grid-template-columns: 30% 25% 45%;   
    margin-top: 30px;
    width: 100%;
    height: 40px;    
}

._starSales {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;   
}
._imgStar {
    width: 75%;
}
.star:nth-child(2){
    width: 50%;
}
._opnionText {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 20px;

}
.valueDesc {
  margin-top: 20px;
  color: #222;
  text-decoration: line-through;
}
.valueDesc span {
  position: absolute;
  font-size: x-small;
  text-decoration: line-through;
}
.valueTot span {
  position: absolute;
  font-size: medium;
  font-family: 'Times New Roman', Times, serif;
  margin-top: 5px;
  color: black;
}
.valueTot {
  margin-top: 10px;
  font-size: 35px;
}
.valueoff {
  display: flex;
  align-items: center;
}
.descriptionOff {
  color: green;
  font-weight: 600;
  margin-left: 25px;
}
.descriptionParcelado {
  display: flex;
  font-size: small;
}
.descriptionParcelado span {
  position: absolute;
  font-size: x-small;
  font-family: 'Times New Roman', Times, serif;
  color: black;
}
.description {
    border: 1px solid #ccc;
    margin-top: 30px;
    border-radius: 5px;
    padding: 7px;
    width: 90%;
}
.pagtos {
  margin-top: 10px;
  color:blue;
  font-size: small;
}
.entrega {
  margin-top: 25px;
  display: flex;
  color: green;
  font-weight: 600;
  grid-gap: 5px;
}
.fpagto {
  color:blue;
  font-size: small;
  margin-top: 5px;
}
.retorno {
  margin-top: 25px;
  display: flex;
  color: green;
  font-weight: 600;
}
.saibamais {
  color:blue;
  font-size: small;
  margin-top: 5px;
}
.prazo {
  color: #2229;
  font-size: small;
  margin-top: 5px;
}
.envio {
  margin-top: 25px;
  color: #808080;
}
.formasEnvio {
  font-size: small;
  margin-top: 5px;
  color: #2229;
}
.calcularFrete {
  color:blue;
  font-size: small;
  margin-top: 5px;
}
.cores {
  font-size: large; 
  color: #2229;
}
.descriptionCor {
  display: flex;
  font-size: large; 
  margin-top: 25px;
  grid-gap: 5px;
 }
.descriptionCor p {
  color: #2229;
}
.descriptionSize {
       display: flex;
    align-items: center;
    margin-top: 15px;
    grid-gap: 10px;   
}
.descriptionSize p {
  color: #2229;
  margin: 0;
}
.gradecor{
  display: flex;
  margin-top: 5px;
  gap: 15px;
  cursor: pointer;  
  text-align: center;  
}
.gradecor img{
    margin-top: 3px;
    width: 65%;    
}
.gradeTamanho{
  display: flex;
  margin-top: 5px;
  gap: 10px;
  cursor: pointer;
}
.size {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  width: 40px;
  height: 40px;
  border-radius: 6px;
  background-color: #fff;
  font-size: small;
  cursor: pointer;
}
.size:hover {
    border: 1px solid #222;
    font-weight: 900;
    
}
.colors:hover {
    border: 1px solid blue;
}
.divOne{
  width: 15%;
  height: 80px;
  border-radius: 5px;
  border: 1px solid #ccc;
}
.divTwo{
  width: 15%;
  height: 80px;
  border-radius: 5px;
  border: 1px solid #ccc;
}
.divThree{
  width: 15%;
  height: 80px;
  border-radius: 5px;
  border: 1px solid #ccc;
} 
.estoque {
  font-size: large;
  margin-top: 25px;
  color: #2229;
}
.guia{
  display: flex;
  align-items: center;
  gap: 15px;
  color:blue;
  font-size: small;
  margin-top: 25px;
}
.guia p { 
  color: blue;
  font-size: large;  
}
.disponivel {
  font-size: large;
  margin-top: 35px;
  color: #2229;
}
._labelCep{
  margin-top: 40px;
  color: green; 
  font-weight: 400;
 
  
}
.InputCep {
  display: flex;
  align-items: center;
  margin-top: 5px;
  grid-gap: 15px;
  border-radius: 4px;
  width: 100%;
  height: 30px; 
}
.InputCep a{  
  font-size: small;
  color: blue;
  text-decoration: none;
}
.InputCep input {
    height: 30px;
    border-radius: 4px;
    outline: none;
    border: 1px solid #222;
    text-indent: 10px;
}
.freteGratis{
  margin-top: 50px;
  display: flex;
  color: green;
  font-weight: 600;  
}
.buttonComprar{
  margin-top: 25px;
  background-color: blue;
  width: 100%;
  height: 44px;
  color: #fff;
  font-family: Proxima Nova,-apple-system,Roboto,Arial,sans-serif;
  font-size: 16px;
  font-weight: 600;
  border-radius: 6px;
  padding: 0 24px;
  text-align: center;
  border: none;
  cursor: pointer;
}
.buttonAdicionar{
    margin-top: 10px;
    background-color: #ccc;
    width: 100%;
    height: 44px;
    color:blue;
    font-family: Proxima Nova,-apple-system,Roboto,Arial,sans-serif;
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px;
    padding: 0 24px;
    text-align: center;
    border: none;    
}
.vendidoPor {
    font-size: small;
    margin-top: 25px;
    color: #2229;      
}
.vendidoPor  span {
    font-size: small;
    color:blue;
}
.garantia {
    font-size: small;
    margin-top: 25px;
    color: #2229;
}
.bold {
    color: blue;
    border: 1px solid blue;
}
