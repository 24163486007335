@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');

body,
h1,
ul,
li,
/* p, */
dd,
dt,
dl,
h2,
h3,

* {
    padding: 0;
    margin: 0;
}
body {
    width: 100vw;
    background-color: var(--corBeige);
}
:root {
    --corBeige: beige;
    --corBlack: #222;
    --corTittle: orange;
    --corTextGray: #7f7e7e;
    --corDropDown: #d3d0d0;
    --corText: #ccc;
}
.headContainner {
    display: block;
    flex-wrap: wrap;
    background-color: var(--corBeige);
    justify-content: center;
    align-items: center;
    margin: 0;
    width: 100%;
    height: 100%;   
}
.divNav {
    display: grid;
    width: 100vw;
    height: 35vh;
    grid-template-columns: 35% 25% 35%;
    justify-content: center;
    /* align-items: center; */
    margin: 0;
    z-index: 1;   
}

.hamburguerMenu {
    display: none; /* Escondido por padrão em telas grandes */
    font-size: 30px;
    cursor: pointer;
    padding: 10px;
}

.menuItems {
    display: none; /* Escondido por padrão */
    flex-direction: column;
    background-color: var(--corBeige);
    position: absolute;
    top: 50px;
    width: 100%;
}

.menuItems ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.menuItems ul li {
    padding: 15px;
    text-align: center;
}

.menuItems ul li a {
    text-decoration: none;
    color: var(--corBlack);
    font-size: 18px;
}

.menuItems.open {
    display: flex; /* Mostrar quando o menu estiver aberto */
}
















.divLeft {
    width: 100%;
    height: 100%; 
    grid-column: 1/2;
}
.divCenter {
    display: block;
    width: 100%;
    height: 100%;   
    grid-column: 2/3;
}
.SectionLogotipo {
    display: flex;
    justify-content: center;
    align-items: center;   
}
.logokz {
    width: 60%;
}
.textKamisariaZanuto {
    display: flex;
    justify-content: center;
    align-items: center;   
    padding: 25px;
}
.textKamisariaZanuto h1 {    
    font-size: 23px;
    letter-spacing: 0.5px;
    color: #090909;   
    white-space: nowrap;
   transform: scaleY(2) scaleX(2) scaleZ(3);
}
.divRight {
   /* display: none; */
}
.divMenu {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20%;
    width: 100%;
    margin-left: 50%;
    transform: translate(-50%);
    margin-top: 20px;   
}
.divUl {
    display: inline-flex;
    width: 97%;
    padding: 3px;       
}
.divUl li {
    display: flex;
    width: 100%;
    align-items: center;
    list-style: none;
    margin: 0;
    justify-content: center;
    text-transform: uppercase;      
}
.divUl li a {
    font-size: 10px;
    text-decoration: none;
    color: #060606;
    letter-spacing: 1px;
    font-weight: 500;
    transform: scale(1.2);
}
.divUl li a:hover {
    color: #97959a;
}
.divUl li span {
    display: flex;
    gap: 5px;
    margin: 2px;
    color: #575555;
}
.traits {
    width: 100%;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ccc;   
}
.traits p {
    display: inline-block;
    transform: scaleX(1.5) scaleY(0.8);  
    color: #060606;   
}
.material-symbols-outlined {
    font-size: 12px;  
}
.dropDown-subMenu {
    display: none;
}
.divNav ul li:hover .dropDown-subMenu {
    display: block;
    background-color: #222;
    position: absolute;
    margin-top: 139px;
    margin-left: 0;
    border-radius: 3px;
    height: 120px;
    width: 120px;
}
.dropDown-subMenu ul li a {
    display: flex;
    width: 100%;
    height: 35px;
    align-items: center;
    margin: 0 auto;
    justify-content: start;
    color: #e6e1e199;
    gap: 10px;
}
.dropDown-subMenu ul li a:hover {
    background-color: #ccc9;
    color: #060606;
}
.divNav ul li:hover .dropDown-subMenu ul li a {
    font-size: 5px;
}
.main {
    display: flex;
    width: 100%;
    height: 350px;
    gap: 10px;    
    margin-top: 60px;   
}
.main-container {
    width: 70%;  
    height: 100%;      
    background-color: var(--corBlack);      
    margin-left: 10px;
    border-radius: 4px 0px 0px 4px;
}
.mainTittle {   
    height: 80px;
    margin-left: 20px;
}
.mainTittle h1 {
    color: var(--corTittle);
    /* transform: scaleY(1.3); */
    letter-spacing: 0.1rem;
    font-family: Arial, Helvetica, sans-serif;
    text-transform: capitalize;
    display: inline-block;
    transform: skewX(-10deg);
    margin-top: 15px;    
}
.mainDescription {
    box-sizing: border-box;  
    flex-direction: column;
    margin-top: 70px; 
    font-family: 'Roboto';
    max-width: 100%;   
    text-align: justify;
    text-indent: 70px;     
    color: var(--corText);  
    background-color: var(--corBlack);
    line-height: 1.6;    
    margin: 0;
  }
.mainDescription p {
    font-family: 'Roboto';  
    font-weight: 600;
    font-size: x-large;
    letter-spacing: 1px; 
    transform: scaleY(1.1);     
    margin-top: 20px;
    width: 96%;
    max-height: 350px;
    margin-left: 20px;
    margin-right: 30px;
    max-width: 98%;  
}
.divPhoto {
    display: flex;
    width: 30%;
    height: 100%;
    border-radius: 0px 5px 5px 0px; 
    justify-content: end;   
    background-color: var(--corBlack);
    margin-right: 30px;
}
.divPhoto img {
    width: 100%;
    height: 100%;
    border-radius: 0px 4px 4px 0px;    
}
.article {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    margin-top: 10px;   
}
.first-article {
    display: grid;
    width: 100%;
    height: 500px;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
    gap: 10px;  
    margin-left: 10px;
    margin-right: 30px;   
}
.aside {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    margin-top: 10px;   
}
.second-aside {
    display: grid;
    width: 100%;
    height: 500px;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-left: 10px;
    margin-right: 30px;
}
.article-left {
    display: flex;
    width: 100%;
    height: 500px;
    justify-content: center;
    align-items: center;
}
.aside-left {
    display: flex;
    width: 100%;
    height: 500px;
    justify-content: center;
    align-items: center;
}
.aside-left img {
    border-radius: 5px;
}
.aside-right img {
    border-radius: 5px;
}
.article-right {
    display: flex;
    width: 100%;
    height: 500px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}
.article-right img {
    border-radius: 5px;
}
.article-left img {
    border-radius: 5px;
}
.aside-right {
    display: flex;
    width: 100%;
    height: 500px;
    justify-content: center;
    align-items: center;
}
.divImg1 {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    justify-content: center;
    align-items: center;
}
.divImg2 {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    justify-content: center;
    align-items: center;
}
.divImg3 {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    justify-content: center;
    align-items: center;
}
.divImg4 {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    justify-content: center;
    align-items: center;
}
.divImg1 img {
    width: 100%;
    height: 100%;
    position: relative;
}
.divImg2 img {
    width: 100%;
    height: 100%;
    position: relative;
}
.divImg3 img {
    width: 100%;
    height: 100%;
    position: relative;
}
.divImg4 img {
    width: 100%;
    height: 100%;
}
.img-txt {
    position: absolute;
    z-index: 1;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    font-size: 40px;
    color: #ccc;
    text-transform: uppercase;
    border-top: 5px solid #ccc;
    border-bottom: 5px solid #ccc;
}
._footerContainer {
    display: block;  
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: var(--corBeige);
  
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 3%;
}
._footer_HomeRowsOne {
    display: grid;
    width: 100%;
    grid-template-columns: 70% 30%;   
    border-radius: 4px;   
    grid-gap: 10px;
}
._footer_home_columnOneLeft {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: #222;  
    grid-column: 1;
    border-radius: 4px;
}
._footer_home_description {
    display: flex;
    justify-content: center;
    align-items: center;    
    width: 100%; 
    height: 100%; 
   padding: 20px;
   margin-left: 10px;
   border-radius: 4px;
 }
._footer_home_description p {
    text-align: justify;
    font-size: 22px;
    line-height: 1.6; /* Ajuste este valor conforme necessário */
    color: #b9b6b6;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 200;
}
._footer_home_column_OneRight {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;   
    border-radius: 4px;   
    grid-column: 2;    
}
._imgsMapa {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 0;   
    cursor: pointer;
    border-radius: 4px;    
}
._click {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;   
    cursor: pointer;    
    border-radius: 4px;
    border: 1px solid #222;
}
._footer_home_column_redesociais {
    display: block;
    width: 100.6%;
    max-width: 100.6;
    height: 100%;     
    margin-top: 10px;
}


.textBaseBoard {
    display: flex;
    align-items: center;
    background-color: var;
    width: 69.6%;
    height: 100%;
    margin-left: 10px;
    border-radius: 4px 0px 0px 4px;
    background-color: var(--corBlack);
}
.textBaseBoard p {   
    font-family: Arial, Helvetica, sans-serif;  
    text-indent: 64px;
   text-align: justify;
    font-size: 15px;
    letter-spacing: 1px; 
    transform: scaleY(1);     
    width: 96%;
    max-height: 350px;
    margin-left: 20px;
    margin-right: 30px;
    max-width: 98%;  
     color: #ccc;   
     line-height: 2.2;
     text-transform: capitalize;
}
.divTextName {
    display: flex;  
    width: 100%;
    height: 40px;
    justify-content: center;
    align-items: center; 
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 400;
    color: #ccc;
    background-color: #575555;
    border-radius: 4px 4px 0px 0px;
    letter-spacing: 1.5px;
    text-transform: capitalize;
}
.iconsSociais {
    display: flex;
    margin: 0;
    gap: 18px;
    margin-top: 1px;
    width: 100%;
    height: 90px;
    justify-content: center;
    align-items: center;
    background-color: var(--corBlack);  
    border-radius: 0px 0px 4px 4px;
}
._sectionHome_Column_OneRight {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26.3%;
    height: 100%;   
    margin: 0;  
    border-radius: 0px 4px 4px 0px;
    background-color: var(--corBlack);
}
.divEnd {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: var(--corRodape);
    width: 100%;
    height: 30px;
    margin: 0;
    grid-column: 1/4;
    margin-top: 10px;
    align-items: center;
    margin-left: 10px;
    margin-right: 30px;
    border-radius: 4px;
}
.divEnd p {
    display: flex;
    font-size: 12px;
    text-align: center;
    justify-content: end;
    color: #ccc;
}
.adress-site {
    margin-right: 14%;
}
.icon-rede {
    background-color: beige;
    margin: 2px;
    border-radius: 4px;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.iconsSociais a {
    color: rgb(99, 98, 98);
}
.finishBoard {
    display: flex;
    width: 100.6%;
    height: 50px;   
    margin-top: 10px;
    background-color: #222;
    border-radius: 4px;
}







