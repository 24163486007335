@media (max-width: 768px) {

   
    
    *{
        padding: 0;
        margin: 0;   
    } 
    .formularioModal {   
        top: 0 !important;
        left: 0 !important;
        position: relative !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        width: 100vw !important;
        height: 100% !important;   
        background-color: hsl(240, 0%, 70%);        
        padding: 5px !important;
    }
    .formularioModal  {
       padding: 5px;
    }
    .containerModal{     
        position: relative !important;
        width: 100% !important;
        height: 100% !important; 
        border-radius: 4px !important;
        padding: 5px !important; 
        margin: 0 !important;    
        background-color: rgba(254, 253, 253, 0.724) !important;
        }
    .dados{   
        display: block !important; 
        width: 100% !important; 
        height: 100% !important;   
    }
    .pEndereco  {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
          color: #222 !important;
          font-size: 28px !important;
        }
    .dadosName {  
        margin-top: 30px !important;
        width: 100% !important;        
    }
    .labelFormname {
        display: block !important;  
        color: rgba(0,0,0,.6) !important;
        font-size: 10px !important;       
    }
    .inputFormname {
        margin-top: 5px !important;
        width: 100% !important;
        font-size: 10px !important;
        font-family: Arial, Helvetica, sans-serif !important;
        color: gray !important;
        padding: 10px !important;
        height: 15px !important;
        border-radius: 2px !important;
        outline: 0 !important;
        border: 1px solid  rgba(0,0,0,.2) !important;
        cursor: pointer !important;
        text-transform:capitalize !important;
        letter-spacing: 0,5px !important;      
        background-color: rgb(241, 244, 246, 0.9) !important;    
    }
    .pFormname {
        margin-top: 5px !important;
        color:  rgba(0,0,0,.5) !important;
        font-size: 10px !important;
    }
    .dadosCep { 
        width: 100% !important;
        height: 55px !important;
        margin-top: 10px !important; 
       
    }
    .labelCep { 
        width: 100% !important;
        height: auto !important;
        display: block !important;  
        color: rgba(0,0,0,.6) !important;
        font-size: 10px !important;     
    }
    .inputCep {
        margin-top: 5px !important;
        width: 100% !important;
        font-size: 10px !important;
        font-family: Arial, Helvetica, sans-serif !important;
        color: gray !important;
        padding: 10px !important;
        height: 25px !important;
        border-radius: 2px !important;
        outline: 0 !important;
        border: 1px solid  rgba(0,0,0,.2) !important;
        cursor: pointer !important;
        text-transform:capitalize !important;
        letter-spacing: 0,5px !important;      
        background-color: rgb(241, 244, 246, 0.9) !important;    
    } 
    .dadosEstado {
          display: flex !important; 
          margin-top: -4px !important;
          width: 100% !important;
          height: auto !important; 
          grid-gap: 20px !important;            
    }
    .divEstado {
          width: 100% !important;          
    }
    .labelEstado {
        font-size: 10px !important;
          display: block !important;
          color:  rgba(0,0,0,.6) !important;
          height: auto !important;
          width: 100% !important;         
    }
    .inputEstado {
        margin-top: 5px !important;
        width: 100% !important;
        font-size: 10px !important;
        font-family: Arial, Helvetica, sans-serif !important;
        color: gray !important;
        padding: 10px !important;
        height: 25px !important;
        border-radius: 2px !important;
        outline: 0 !important;
        border: 1px solid  rgba(0,0,0,.2) !important;
        cursor: pointer !important;
        text-transform:capitalize !important;
        letter-spacing: 0,5px !important;      
        background-color: rgb(241, 244, 246, 0.9) !important;    
    }
    .divCidade {
          width: 100% !important;
    }
    .labelCidade {
          display: block !important;
          color:  rgba(0,0,0,.6) !important;
          height: auto !important;
          width: 100% !important;         
          font-size: 10px !important;
    }
    .inputCidade {
        margin-top: 5px !important;
        width: 100% !important;
        font-size: 10px !important;
        font-family: Arial, Helvetica, sans-seri !important;
        color: gray !important;
        padding: 10px !important;
        height: 25px !important;
        border-radius: 2px !important;
        outline: 0 !important;
        border: 1px solid  rgba(0,0,0,.2) !important;
        cursor: pointer !important;
        text-transform:capitalize !important;
        letter-spacing: 0,5px;      
        background-color: rgb(241, 244, 246, 0.9) !important;       
    }
    .dadosBairro {
        width: 100% !important;
        height: auto !important;
        margin-top: 5px !important;  
    }
    .labelBairro:nth-child(1) {
        font-size: 10px !important;
        display: block !important;
        color:  rgba(0,0,0,.6) !important;
        height: auto !important;
        width: 100% !important;         
    }
    .inputBairro:nth-child(2) {
        margin-top: 5px !important;
        width: 100% !important;
        font-size: 10px !important;
        font-family: Arial, Helvetica, sans-serif !important;
        color: gray !important;
        padding: 10px !important;
        height: 25px !important;
        border-radius: 2px !important;
        outline: 0 !important;
        border: 1px solid  rgba(0,0,0,.2) !important;
        cursor: pointer !important;
        text-transform:capitalize !important;
        letter-spacing: 0,5px !important;      
        background-color: rgb(241, 244, 246, 0.9) !important;    
    }
    .dadosEndereco { 
          display: flex !important; 
          margin-top: 5px !important;
          width: 100% !important;
          height: auto !important; 
          grid-gap: 20px !important;     
    }
    .divEndereco {   
          width: 100% !important;
    }
    .labelEndereco {
        font-size: 10px !important;
        display: block !important;
        color:  rgba(0,0,0,.6) !important;
        height: auto !important;
        width: 100% !important;         
    }
    .inputEndereco {
        margin-top: 5px !important;
        width: 100% !important;
        font-size: 10px !important;
        font-family: Arial, Helvetica, sans-serif !important;
        color: gray !important;
        padding: 10px !important;
        height: 25px !important;
        border-radius: 2px !important;
        outline: 0 !important;
        border: 1px solid  rgba(0,0,0,.2) !important;
        cursor: pointer !important;
        text-transform:capitalize !important;
        letter-spacing: 0,5px !important;      
        background-color: rgb(241, 244, 246, 0.9) !important;    
    }
    .labelNumero {
        font-size: 10px !important;
        display: block !important;
        color:  rgba(0,0,0,.6) !important;
        height: auto !important;
        width: 100% !important;         
    }
    .inputNumero {
        margin-top: 5px !important;
        width: 100% !important;
        font-size: 10px !important;
        font-family: Arial, Helvetica, sans-seri !important;
        color: gray !important;
        padding: 10px !important;
        height: 25px !important;
        border-radius: 2px !important;
        outline: 0 !important;
        border: 1px solid  rgba(0,0,0,.2) !important;
        cursor: pointer !important;
        text-transform:capitalize !important;
        letter-spacing: 0,5px !important;      
        background-color: rgb(241, 244, 246, 0.9) !important;    
        }
    .dadosNumero {   
          width: 100% !important;  
    }
    .res p:nth-child(4) {
        color: red !important;
        font-size: 12px !important;
    }
    .dadosCep p:nth-child(3) {
        color: red !important;
        font-size: 10px !important;
        width: 100% !important;
        height: auto !important;
    }
    .dadosOpcional{
          margin-top: 5px !important;
          width: 100% !important;    
    }
    .labelOpcional {
        font-size: 10px !important;
        display: block !important;
        color:  rgba(0,0,0,.6) !important;
        height: auto !important;
        width: 100% !important;         
    }
    .inputOpcional {   
        margin-top: 5px !important;
        width: 100% !important;
        font-size: 10px !important;
        font-family: Arial, Helvetica, sans-serif !important;
        color: gray !important;
        padding: 10px !important;
        height: 25px !important;
        border-radius: 2px !important;
        outline: 0 !important;
        border: 1px solid  rgba(0,0,0,.2) !important;
        cursor: pointer !important;
        text-transform:capitalize !important;
        letter-spacing: 0,5px !important;      
        background-color: rgb(241, 244, 246, 0.9 !important);    
    }
    .dadosLocal {
          display: block;
          margin-top: 5px !important;
          width: 100% !important;  
          height: auto !important;
    }
    .dadosLocal label {
        font-size: 10px !important;
        display: block !important;
        color:  rgba(0,0,0,.6) !important;
        height: auto !important;
        width: 100% !important;    
        margin-top: 6px !important;     
    }
    .divWork{
          margin-top: 5px !important;
          display: flex !important;
          width: 100% !important;
          grid-gap: 10px !important;   
        
    }
    .divWork label{
          color:  rgba(0,0,0,.6) !important;
    }
    .inputRadio {
          width: 25px !important;
          cursor: pointer !important;
          border: none !important;
    }
    .dadosPhone {
          margin-top: 5px !important;
          width: 100% !important;
    }
    .labelPhone {
        font-size: 10px !important;
        display: block !important;
        color:  rgba(0,0,0,.6);
        height: auto !important;
        width: 100% !important;         
    }
    .inputPhone {
        margin-top: 5px !important;
        width: 100% !important;
        font-size: 10px !important;
        font-family: Arial, Helvetica, sans-serif !important;
        color: gray !important;
        padding: 10px !important;
        height: 25px !important;
        border-radius: 2px !important;
        outline: 0 !important;
        border: 1px solid  rgba(0,0,0,.2) !important;
        cursor: pointer !important;
        text-transform:capitalize !important;
        letter-spacing: 0,5px !important;      
        background-color: rgb(241, 244, 246, 0.9) !important;    
    }
    .radioRes {
         width: 25px !important;
          margin-left: 5% !important;
          outline: none !important;
    }
      /* .divWork  */
    .dadosAdicionais {
          margin-top: 5px !important;
          width: 100% !important;    
    }
    .labelAdicional {
        font-size: 10px !important;
        display: block !important;
        color:  rgba(0,0,0,.6) !important;
        height: auto !important;
        width: 100% !important;         
    }
    .inputAdicional {
          width: 100% !important;
          height: 55px !important;
          border-radius: 4px !important;
          border: 1px solid  rgba(0,0,0,.2) !important;
          color:  rgba(0,0,0,.9) !important;         
          margin-top: 5px !important;  
          font-size: 10px !important;         
          outline: 0 !important;
          cursor: pointer !important;
          text-transform: capitalize !important;
    }
    .divButton {
          display: flex !important;
          justify-content: end !important;              
          width: 100% !important;
          height: 70px !important;
          align-items: center !important;     
    }
    .divButton>:nth-child(1) {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
         background-color: #3483fa !important;
         border-radius: 4px !important;         
          font-family: Proxima Nova,-apple-system,Roboto,Arial,sans-serif !important;
          font-size: 10px !important;
          font-weight: 600 !important;
          height: 25px !important;        
          /* width: auto; */
          color: #fff !important;
          cursor: pointer !important;         
    }
}