
@media screen and (max-width: 768px) {
   
    .headContainner {        
        width: 100%;    
        height: 100%;     
        cursor: pointer;       
    }
    .divNav {
        display: flex;
        justify-content: center;
        align-items: center; 
        width: 100%;
        height: 200px;      
        background-color: beige;    
    }

    .divLeft {
        display: none;
        width: 5px;       
    }    
    .divCenter {
        display: block;       
        width: 70%;
        height: 100%;
        padding: 5px;        
        line-height: 1.5em;         
    }
    .SectionLogotipo {
        display: flex;
        justify-content: center;        
        width: 100%;
        height: 65%;
        margin-top: 7px;             
    }
    .logokz {       
        width: 35%;
    }
    .textKamisariaZanuto {
        display: flex;
        justify-content: center;
        align-items:center ;
        width: 100%;     
        height: 10%;                  
    }
    .textKamisariaZanuto h1 {  
        display: inline-block;  
        margin: 0;
        font-size: 14px;
        transform: scaleX(1.6) scaleY(3);         
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-weight: bold;
        }
    
    .divRight {
        /* display: none;        */
     } 

     .divMenu {
        display: none;
     }
     .hamburguerMenu {
        display: block; /* Mostrar no mobile */  
        cursor: pointer;     
        position: absolute;
        top: 20px;
        right: 30px;
    }

    .menuItems {
        display: none;     
        background-color: #b9b6b6;   
        right: 40px;
        margin-top: 20px;
        width: 110px;
    }

    .menuItems.open {
        display: block;
    }













    


.main {
    display: flex;
    width: 100%;
    height: 170px;   
    border-radius: 0px;  
    margin-left: 10px; 
    padding: 0;
    margin-top: 1px;
    gap: 5px;
    margin-left: 1px;   
}
.main-container {
    width: 70%;
    height: 100%;
   margin-left: 5px;  
    border-radius: 0px;       
    border-radius: 4px;
}
.mainTittle {
    width: 100%;
    height: 45px;   
    border-radius: 4px 0px 0px 0px;
    margin: 0;    
}
.mainTittle h1 {
    font-size: 16px;
    margin-left: 10px;
    text-decoration: none;
    margin-top: 5px;
}
.mainDescription {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;  
    max-height: 73.3%;
    margin: 0;
    padding: 5px;
    border-radius: 0px 0px 0px 4px;   
    margin-top: -10px;
}
.mainDescription p {
    display: flex;  
    font-size: 9px; 
    line-height: 1.6;
    width: 96%;
    font-weight: 600px;
    text-align: justify;
    font-family: 'Roboto', sans-serif;
    padding: 0;
    margin: 0;  
    color: white;
}
.divPhoto {
    width: 40%;
    border-radius: 0px 4px 4px 0px;   
    margin-right: 5px;
}
.main-right {
    display: none;
    background-color: #ccc;
    width: 100%;
    height: 100%;
     border-radius: 0px 4px 4px 0px;   
}
.divPhoto img{
    border-radius: 0px;   
    border-radius: 0px 4px 4px 0px;   
}
.article { 
display: flex; 
justify-content: center;
margin-top: 5px;
width: 100%;
}
.first-article {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 170px;
    gap: 5px;     
    margin: 0; 
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 4px;
}
.article-left {
    width: 100%;
    height: 100%;
}
.img-txt {
    font-size: 14px;
}
.article-right {   
    width: 100%;
    height: 100%;
    border-radius: 0px;
}
.aside {
    display: flex; 
    justify-content: center;
    /* margin: 0; */
    width: 100%;
    height: 170px;
    margin-top: 45px;    
}
.second-aside {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 170px;
    gap: 5px;      
    margin: 0;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 4px;
}
.aside-left {
    width: 100%;
    height: 100%;
    border-radius: 5px;   
}
.aside-right {  
  width: 100%;
  height: 100%;
  border-radius: 5px;
 }
 .divImg3 {
    width: 100%;
    height: 100%;
    border-radius: 5px;
 } 
._footerContainer {
    width: 100%;
    height: 430px;    
    border-radius: 0;   
}
._footer_HomeRowsOne {
    display: grid;
    width: 100%;
    grid-template-columns: 70% 30%;   
    border-radius: 4px;   
    grid-gap: 10px;
    border-radius: 4px;
    margin-left: -5px;
}
._footer_home_columnOneLeft {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: #222;  
    grid-column: 1;
    border-radius: 4px;
}
._footer_home_description {
    display: flex;
    justify-content: center;
    align-items: center;    
    width: 100%; 
    max-height: 100%; 
    padding: 20px;
    margin-left: 5px;
     border-radius: 4px;  
 } 
._footer_home_description p {
    text-align: justify;
    font-size: 10px;
    line-height: 1.6; /* Ajuste este valor conforme necessário */
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 200;
}
 ._footer_home_column_OneRight {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;   
    border-radius: 4px;   
    grid-column: 2;    
}
._click {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;   
    cursor: pointer;    
    border-radius: 4px;
    border: 1px solid #222;
}
._imgsMapa {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 0;   
    cursor: pointer;
    border-radius: 4px;    
}
._footer_home_column_redesociais {
    display: block;
    width: 103%;    
    height: 100px;
    margin-top: 10px;
    background-color: orange;
    margin-left: -5px;
    margin-right: -15px;    
}
.divTextName {
    display: flex;   
    text-align: center;
    justify-content: center;    
    width: 100%;    
    border-radius: 4px 4px 0px 0px; 
}
.iconsSociais {
    display: flex;
    align-items: center;    
    margin: 0;
    width: 100%;
    cursor: pointer;    
    height: 90px;
    background-color:   var(--corBlack);   
    margin-top: 1px; 
    background-color: var(--corBlack);
}
.finishBoard {
    display: flex;   
    justify-content: center;   
    width: 103%;
    height: 50px;   
    margin-left: -5px;
    margin-top: 32px;
}
.divEnd {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;  
    justify-content: space-between;   
    margin: 0;
}
.divEnd p:nth-child(1) {
    display: flex;
    justify-content: start;
    margin-left: 10px;       
    width: 50%;
    font-style: 10px;
    font-size: 10px;
    letter-spacing: 1px;
}
.divEnd p:nth-child(2) {   
    display: flex;
    width: 45%;
    margin-left: -30px;
    margin: 0;    
    font-size: 10px;
    margin-right: 20px;
    letter-spacing: 1px;
}
}
















